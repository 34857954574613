$white: #fff !default;
$black: #000 !default;

$red: #d41217;
$red-light: #e15a5d;

$orange: #e84d0e;
$orange-light: #ef8357;

$orange-yellow: #f39200;
$orange-yellow-light: #f08d5f;

$yellow: #fbba00;
$yellow-light: #fccf4d;

$brown: #7d583c;
$brown-light: #a48a77;

$taupe: #ab9681;
$taupe-light: #c4b6a7;

$green: #596831;
$green-light: #8b966f;

$blue: #345d6d;
$teal: #5bc0de;

// Create beigescale
$beige-dark: #eae1c8;
$beige: #f0ead9;
$beige-light: #f6f1e6;
$beige-lighter: #fbf9f4;

// Create grayscale
$gray-dark: #292b2c !default;
$gray: #464a4c !default;
$gray-light: #636c72 !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;

$assets_path: "../assets";

// Base colors
$brand-primary: $red;
$brand-secondary: $green;
$brand-tertiairy: $beige-dark;

$main: #80975f;
$navigation-primary-background: #203500;
$navigation-primary-text: #9ab27a;

$navigation-affix-top-text: #bf1d32;

$navigation-secondary-background: #fff;
$navigation-secondary-text: $main;
$navbar-secondary-text: #80975f;

$background-white: white;
$background-orange: #f6950a;
$background-orange-light: #f6950a;
$background-brown-earth: #91623a;
$background-green: #80975f;
$background-green-light: #a0bd76;
$background-green-dark: #6e8250;
$background-yellow: #fff7c9;
$background-yellow-sun: #fff7c9;
$background-yellow-light: #fff7c9;
$background-taupe: #aa896d;
$background-taupe-light: #aa896d;
$background-blue: #636c72;
$background-beige: #f0ead9;
$background-beige-light: #f1f1f1;
$background-beige-dark: #eaeaea;
$background-beige-lighter: #f9f9f9;
$background-red-light: #c01527;
$btn-primary-color: #a0bd76;

$btn-main: $main;
$btn-main-border: $main;
$btn-main-color: white;

$btn-main-hover: #a0bd76;
$btn-main-hover-border: #a0bd76;
$btn-main-hover-color: white;

$btn-beige: #dbe6ca;

$btn-booking: #bf1d32;
$btn-booking-color: white;
$btn-booking-border: #bf1d32;

$btn-booking-hover: #d7493e;
$btn-booking-hover-color: white;
$btn-booking-hover-border: #d7493e;

$btn-ghost: transparent;
$btn-ghost-border: #000;
$btn-ghost-color: black;

$btn-ghost-hover: black;
$btn-ghost-hover-border: black;
$btn-ghost-hover-color: white;

$btn-navigation-background: $main;
$btn-navigation-background-hover: #a0bd76;

$card-background-even: #dbe6ca;
$card-background-odd: #fafff2;
$card-background-green-dark: #6e8250;
$card-background-green-light: #a0bd76;
$card-background-beige-sand: #aa896d;
$card-background-red-light: #c01527;
$card-background-orange-light: #f6950a;
$card-background-brown-light: #f9f9f9;
$card-background-brown-earth: #91623a;
$card-background-taupe: #eaeaea;
$card-background-taupe-light: #f1f1f1;
$card-background-yellow-sun: #fff7c9;
$card-background-white: white;

$card-property-color: #a0bd76;

$footer-text-color: $black;
$footer-submit-text-color: $white;

$footer-light-tone: #eaeaea;
$footer-mid-tone: #f9f9f9;
$footer-dark-tone: #9a9a9a;

$footer-social-media-background-color: #dbe6ca;
$footer-social-media-background-hover-color: #9ab27a;
$footer-social-media-icon-color: #9ab27a;
$footer-social-media-icon-hover-color: #a0bd76;
$footer-social-media-border-color: #a0bd76;

$footer-checked-items-color: white;
$checked-items-color: #bf1d32;

$result-body: #f9f9f9;

$price-tag-background: #fff7c9;
$price-tag-background-unavailable: #dbe6ca;

$material-ui-primary: $orange-yellow;

$filter-unfocused: #eaeaea;
$filter-focused: #f1f1f1;

$filter-after-color: #bf1d32;
$filter-before-color: #bfbfbf;

$calendar-outside: #f1f1f1;
$calendar-blocked: #eaeaea;
$calendar-selected: #a0bd76;

$accommodation-info-header: $main;
$accommodation-info-header-color: white;
$accommodation-info-background: #eaeaea;

$subject-picker-color: white;

$picker-text-color: $main;
$picker-text-transform: none;
$picker-border-bottom: 2px solid white;

$swp: #9a9a9a;

$price-table-active-column: #f9f9f9;
$price-table-header-background: #eaeaea;
$price-table-header-border-bottom: 2px solid #d0c6bb;
$price-table-cell-hover-background: #dbe6ca;
$price-table-cell-hover-shadow: none;
$price-table-cell-border-bottom: none;
$price-table-cell-active: #fccf4d;
$price-table-cell-active-color: #292b2c;
$price-table-cell-active-hover-shadow: inset 0px 0px 0px 2px #d0c6bb;
$price-table-no-price-found: #d0c6bb;
$price-table-arrow-color: #e84301;
$price-table-arrow-background: #f9f9f9;
$price-table-arrow-hover-background: #f9f9f9;
$price-table-receipt-background: #f9f9f9;
$price-table-receipt-border: 1px solid #f9f9f9;

$booking-btn: #bf1d32;
$booking-btn-border: #bf1d32;
$booking-btn-border-radius: 5px;
$booking-bg: #f9f9f9;
$booking-hr: #f9f9f9;

$booking-row: #758c56;
$booking-row-unselected: #96b56d;
$booking-step: #596831;
$booking-step-unselected: #596831;
$booking-close-button: #596831;

$faded-light: #f1f1f1;

$booking-card-footer: #a0bd76;
$modal-odd: #dbe6ca;
$modal-even: #f6f1e6;
$modal-button: #bf1d32;
$modal-button-hover: #d7493e;

$modal-travel-company-button: #596831;
$modal-travel-company-button-color: #596831;

$navbar-search-background: #dbe6ca;
$navbar-search-text-color: black;
$navbar-search-text-placeholder-color: black;

$jumbotron-button-background: $main;
$jumbotron-button-color: white;
$jumbotron-button-border: 2px solid $main;

$jumbotron-button-hover-background: #a0bd76;
$jumbotron-button-hover-color: white;
$jumbotron-button-hover-border: 2px solid #a0bd76;

$gallery-icon-color: #bf1d32;
$list-features-border-color: #bfbfbf;

$text-add: black;
$loader: url(/img/logos/logo-dierenbos.svg);
$loader-bg: #eaeaea;

$accordion-border-color: #dbe6ca;
$accordion-arrow: #bf1d32;
$accordion-arrow-collapsed: #d7493e;
$accordion-title: #596831;
$accordion-title-collapsed: #758c56;

$tabs-bg: #dbe6ca;
$tabs-bg-hover: #80975f;
$tabs-bg-active: #80975f;

$table-striped-odd-bg: #dbe6ca;
$table-striped-border: #a0bd76;
$table-striped-header-border: #596831;

$cloudfront-url: "https://d214t2x9zeu1qk.cloudfront.net/";

@font-face {
  font-family: "Raleway";
  src: url($assets_path + "/fonts/Raleway-Bold.eot?#iefix")
      format("embedded-opentype"),
    url($assets_path + "/fonts/Raleway-Bold.woff") format("woff"),
    url($assets_path + "/fonts/Raleway-Bold.ttf") format("truetype"),
    url($assets_path + "/fonts/Raleway-Bold.svg#Raleway") format("svg");
  font-weight: 700;
  font-style: normal;
}

// @font-face {
//   font-family: 'Raleway';
//   src: url($assets_path + '/fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
//        url($assets_path + '/fonts/Raleway-Regular.woff') format('woff'),
//        url($assets_path + '/fonts/Raleway-Regular.ttf') format('truetype'),
//        url($assets_path + '/fonts/Raleway-Regular.svg#Raleway') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
@font-face {
  font-family: "Lato";
  src: url($assets_path + "/fonts/lato-bold.eot?#iefix")
      format("embedded-opentype"),
    url($assets_path + "/fonts/lato-bold.woff") format("woff"),
    url($assets_path + "/fonts/lato-bold.woff2") format("woff2"),
    url($assets_path + "/fonts/lato-bold.ttf") format("truetype"),
    url($assets_path + "/fonts/lato-bold.svg#lato") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url($assets_path + "/fonts/lato-regular.eot?#iefix")
      format("embedded-opentype"),
    url($assets_path + "/fonts/lato-regular.woff") format("woff"),
    url($assets_path + "/fonts/lato-regular.woff2") format("woff2"),
    url($assets_path + "/fonts/lato-regular.ttf") format("truetype"),
    url($assets_path + "/fonts/lato-regular.svg#lato") format("svg");
  font-weight: normal;
  font-style: normal;
}

$font-family-sans-serif: "Lato", Arial, sans-serif;
$font-family-secondary: "Raleway";
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-main-nav: $font-family-sans-serif;
$font-size-base: 1rem;
$headings-font-family: $font-family-secondary;

$headings-font-weight: 700;
$headings-text-transform: none;

@import "base";

.btn {
  border-radius: 5px;
  &.btn-lg {
    border-radius: 5px;
  }
}

.accommodation-result {
  h2 > * {
    color: $main;
  }

  .result-inner {
    -webkit-box-shadow: 11px 12px 19px -10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 11px 12px 19px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 11px 12px 19px -10px rgba(0, 0, 0, 0.1);
  }
}

.DayPicker
  .DayPickerNavigation
  .DayPickerNavigation_button
  button.btn-navigation.override {
  background: $btn-navigation-background !important;
  border-color: $btn-navigation-background !important;
  &:hover {
    background: $btn-navigation-background-hover !important;
    border-color: $btn-navigation-background-hover !important;
  }
}

.nav-item {
  font-size: 1.125rem !important;
  border: none !important;

  a {
    font-size: 1.125rem;
    &:hover {
      color: #df3046 !important;
    }
  }
}

nav {
  .navbar-brand {
    @media (min-width: 992px) {
      top: 2rem;
    }
  }

  .navbar-toggler .icon-bar {
    background: #758c56 !important;
  }

  @include media-breakpoint-down(md) {
    .navbar-header {
      background: #fff;
    }

    .navbar-brand {
      background-image: url($cloudfront-url + "img/logo.svg") !important;
      background-size: 100% 100%;
    }

    .navbar-container {
      .navbar-main {
        background: #fff;
      }
      .navbar-top {
        background: #fff;
      }
      .navbar-nav li a {
        color: $navigation-affix-top-text;
      }
    }
  }

  .navbar-main {
    > {
      .navbar-nav {
        .nav-link {
          color: $navigation-affix-top-text;
          font-weight: 700;
          letter-spacing: 0.3px;
          text-transform: none !important;
        }
      }
    }
  }
}

.navbar-background {
  background-color: #fff;
  height: 91px;
  width: 100%;
  position: absolute;
  top: 47px;

  @media (max-width: 991px) {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    &.affix {
      &.no-sub {
        .nav-link {
          padding: 0.5rem 1.3rem !important;
        }

        .navbar-brand {
          background-image: url($cloudfront-url + "img/logo.svg") !important;
          background-size: 100% 100%;
        }
      }
    }
  }
  nav .navbar-main > .navbar-nav .nav-link {
    padding: 0.5rem 1.4rem !important;
  }
}

@include media-breakpoint-down(lg) {
  .navbar {
    &.affix {
      &.no-sub {
        .navbar-main {
          .nav-link {
            padding: 0.5rem 0.3rem !important;
          }
        }

        @include media-breakpoint-up(lg) {
          .navbar-brand {
            background-image: url($cloudfront-url + "img/logo.svg") !important;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  nav .navbar-main > .navbar-nav .nav-link {
    padding: 0.5rem !important;
  }
}

@include media-breakpoint-down(md) {
  .navbar.affix.no-sub .navbar-main > .navbar-nav .nav-link,
  nav .navbar-main > .navbar-nav .nav-link {
    padding: 0.5rem 1rem !important;
  }
}

#booking {
  li div h5.text-info {
    color: #596831 !important;
  }
}

.text-black {
  color: black;
}
