// $brand-secondary:               $beige-dark;

$booking-section-bg:            #fff;
$booking-bar-bg:                #cd3a06;

$card-block-bg:                 $beige-light;
$card-footer-bg:                $beige-dark;

$custom-control-border-color:   #dbc897;

$form-group-odd-bg:             $beige-light;
$form-numeric-bg:               $beige-light;
$form-numeric-btn-bg:           $beige-dark;
$form-rich-check-bg:            $beige-dark;

$hr-color:                      $beige-light;

$list-border-color:             $brand-warning;
$list-checked-color:            $green;

$nav-pills-border-color:        $beige-dark;

$text-transform:                uppercase;

$section-header-span-width:     80px;
$section-header-span-height:    80px;

$social-border-color:           $beige-dark;
